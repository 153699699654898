.third-section {
  background-image: url("https://framerusercontent.com/images/ZMAc2OIMLBXENmMtxkzyTRt9wk.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 150px;
  position: relative;
  color: white;
  margin-bottom: -150px;
  width: 100%;
}

.third-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -70px;
}

.third-content-left {
  flex: 1;
  text-align: center;
  margin-left: 150px;
}

.third-content-left-h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  white-space: nowrap;
}

.third-content-left-p1 {
  font-size: 2.5rem;
  font-weight: 100;
}

.third-content-right {
  flex: 2;
  text-align: left;
  padding: 0 6%;
}

.third-content-right h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: left;
}

.third-content-right p {
  font-size: 1.15rem;
  margin-bottom: 20px;
  opacity: 0.6;
  line-height: 1.8;
}

.third-content-right h2 span {
  color: rgb(247, 225, 169);
}

/* Responsive Styles */
/* 태블릿 가로, 세로 : 810px-1099px */
@media all and (min-width: 810px) and (max-width: 1099px) {
  .third-content {
    flex-direction: column;
    margin-left: -70px;
    max-width: 100%;
    padding-left: 5%;
  }

  .third-content-left,
  .third-content-right {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .third-content-left-h2 {
    font-size: 2.5rem;
    text-align: left;
    margin-left: -100px;
  }

  .third-content-left-h2 span {
    font-weight: 200;
  }
  .third-content-left span {
    font-weight: 100;
    color: white;
  }

  .third-content-left-p1 {
    font-size: 2rem;
    text-align: center;
    font-weight: 300;
    margin-left: 200px;
  }

  .third-content-right h2 {
    font-size: 1.5rem;
    text-align: left;
  }

  .third-content-right p {
    font-size: 1.2rem;
    text-align: left;
    white-space: unset;
  }
}

/* 모바일 가로, 세로 : -809px */
@media all and (max-width: 809px) {
  .third-section {
    margin-top: 200px;
    margin-bottom: 0px;
  }

  .third-content {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%; /* 양쪽 여백을 좁게 설정 */
    margin-left: auto;
    white-space: unset;
  }

  .third-content-left,
  .third-content-right {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0px;
  }

  .third-content-left-h2 {
    font-size: 2rem;
    text-align: center;
    margin: 0;
  }

  .third-content-left-p1 {
    font-size: 2rem;
    text-align: center;
    font-weight: 300;
    margin: 0;
    white-space: nowrap;
    color: white;
  }

  .third-content-right {
    text-align: left;
  }

  .third-content-right h2 {
    font-size: 1.1rem;
    white-space: unset;
    margin-left: -17px;
    
  }

  .third-content-right p {
    font-size: 1rem;
    white-space: unset;
    margin-left: -17px;
  }
}
