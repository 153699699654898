.fifth-section {
  display: flex;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 229, 212, 1) 60%,
    rgba(200, 171, 230, 1) 83.6%,
    rgba(112, 64, 249, 1) 100%
  );
  min-height: 60vh;
  width: 100%;
  padding: 20px; /* 패딩 추가 */
}

.fifth-section-container {
  display: flex;
  flex-direction: row;
  max-width: 1100px; /* 최대 너비 설정 */
  width: 100%;
  justify-content: space-between; /* 요소 간의 간격 조정 */
}

.fifth-section-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.fifth-section-image img {
  max-width: 500px;
  height: auto;
}

.fifth-section-content {
  flex: 1;
  padding: 20px;
  text-align: left;
}
.fifth-section-content p {
  color: #3e0d6b;
}

.fifth-section-h2 {
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 2rem;
  color: #3e0d6b;
}

.fifth-section-span {
  font-weight: bold;
  color: #8a2be2;
}

.fifth-section-span2 {
  font-size: small;
}

.fifth-section-content p {
  line-height: 1.7rem;
  font-size: 1.2rem;
}

.cta-button {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  border: none;
  padding: 12px 12px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1rem;
}

/* 태블릿: 810px - 1099px */
@media all and (min-width: 810px) and (max-width: 1099px) {
  .fifth-section {
    flex-direction: column;
    align-items: center;
  }

  .fifth-section-container {
    flex-direction: column;
    align-items: center;
  }

  .fifth-section-image img {
    width: 100%;
  }
  .fifth-section-h2{
    text-align: center;
  }

  .fifth-section-content {
    text-align: center;
  }

  .fifth-section-content p {
    text-align: center;
  }

  .cta-button {
    margin: 20px auto;
  }
}

/* 모바일: 0px - 809px */
@media all and (max-width: 809px) {
  .fifth-section {
    flex-direction: column;
    align-items: center;
  }

  .fifth-section-container {
    flex-direction: column;
    align-items: center;
  }

  .fifth-section-image img {
    width: 90%;
  }

  .fifth-section-h2 {
    font-size: 2rem;
    text-align: center;
  }

  .fifth-section-content {
    text-align: center;
  }

  .fifth-section-content p {
    font-size: 1.1rem;
    text-align: center;
    
  }

  .cta-button {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}
