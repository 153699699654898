* {
  box-sizing: border-box; /* 모든 요소에 box-sizing: border-box 적용 */
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.footer {
  background: #7040f9;
  height: 15rem;
  color: white;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  text-align: left;
  color: white;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 30px;
}

.footer-left-p {
  margin-top: 25px;
  font-size: small;
  line-height: 1.2rem;
  padding-left: 30px;
}

.footer-logo img {
  width: 120px;
  height: auto;
  margin-right: 10px;
}

.footer-right {
  text-align: right;
}

.slogan {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10px;
  padding-right: 30px;
}

.copyright {
  margin-top: 30px;
  font-size: 0.9rem;
  padding-right: 30px;
}

@media all and (min-width: 810px) and (max-width: 1023px) {
  .footer{
    opacity: 1;
  }
  .footer-right {
    text-align: right;
  }
}

@media all and (max-width: 809px) {
  .footer {
    max-width: 100%;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 90px; 
  }

  .footer-left-p {
    margin-top: 10px;
    font-size: 0.8rem;
    text-align: center;
    margin-right: 6px;
  }
  
  .footer-left-p strong {
    font-size: 1rem;
  }

  .footer-right {
    text-align: center;
    margin-top: 20px;
  }

  .slogan {
    font-size: 0.9rem;
    margin-bottom: -20px;
  }

  .copyright {
    font-size: 0.7rem;
    margin-left: 60px; 
  }
}
