* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 1000;
  margin-left: 15px;
}

.logo {
  height: 40px;
}

section {
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  text-align: center;
}

.first-section {
  background-image: url("https://framerusercontent.com/images/dqohVd774VVY7wUKhZBzpRefQpA.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: flex-start;
  padding-top: 150px;
}
.first-section h1 {
  margin-bottom: -0.7em;
  font-size: 3em;
}

.first-section p {
  margin-top: 2.5em;
  font-size: 1.2rem;
  font-weight: 450;
  line-height: 1.3;
}
.first-section h1 span {
  color: rgb(247, 225, 169);
}

h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
  white-space: nowrap;
}

p {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto;
}

.slider-container {
  overflow: hidden;
  white-space: nowrap;
  background-color: #9370db;
  padding-top: 31px;
  padding-bottom: 31px;
}

.slider {
  display: inline-block;
  animation: slide 30s linear infinite;
}

.slider img {
  height: 40px;
  margin: 0 50px;
  vertical-align: middle;
  opacity: 0.3;
  filter: grayscale();
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@media all and (min-width: 810px) and (max-width: 1099px) {
  .first-section{
    min-height: 88vh; /* 배경 높이조절 */
  }
  .first-section h1 {
    font-size: 2.3rem;
  }
  .first-section p {
    font-size: 23px;
    opacity: 0.7;
  }
  
}

@media all and (max-width: 809px) {
  .first-section{
    min-height: 90vh; /* 배경 높이조절 */
  }
  .first-section h1 {
    font-size: 2rem;
  }
  .first-section p {
    text-align: center;
    opacity: 0.7;
  }
  .slider-container {
    padding-top: 10px;
    padding-bottom: 8px;
  }
}
