.fourth-section {
  margin-bottom: -100px; /* 이 부분은 섹션 간의 간격을 줄이는 데 사용됩니다. 필요에 따라 조정하세요. */
  max-width: 100%;
  padding: 100px 100px;
}
.fourth-h2 {
  color: #8a2be2;
  font-size: 40px;
  margin-bottom: 100px;
  margin-top: 170px;
}
.fourth-typograpy {
  text-align: left;
  font-size: 19px;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 한 줄로 제한 */
  -webkit-box-orient: vertical;
}


.grid-container {
  max-width: 1100px; /* 그리드 컨테이너의 최대 너비 설정 */
  justify-content: flex-start; /* 왼쪽 정렬 */
  margin: auto 0px;
}

.card {
  max-width: 345px; /* 카드의 최대 너비 설정 */
  margin: 10px auto;
}

.fourth-h2 {
  text-align: center;
  margin-bottom: 80px;
  
}

.fourth-typograpy {
  text-align: left;
  color:black;
}


/*태블릿 가로, 세로 : 810px-1099px*/
@media all and (min-width:810px) and (max-width:1099px){
	.fourth-typograpy {
    text-align: left;
    font-size: 19px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
}

/*모바일 가로, 세로 : -809px*/
@media all and (max-width:809px){
	.fourth-section{
        display: none;
    }
}
