* {
    box-sizing: border-box;
}
.second-section {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
}

.container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    margin-bottom: -30px;
}

.container:nth-child(even) {
    flex-direction: row;
}

.container img {
    width: 100%;
    max-width: 500px;
    height: auto;
    left: 0;
}

.container .content {
    flex: 1;
    margin-right: 20px;
    margin-left: 20px;
}

.container .content h2, .container .content p {
    color: black;
    white-space: nowrap;
    font-weight: normal;
}

.container:nth-child(even) .content {
    flex: 1;
}

h2 {
    font-size: 35px;
    font-weight: normal;
    color: white;
    margin-bottom: 20px;
    text-align: left;
    line-height: 1.3;
}

h2 span {
    font-weight: bold;
    color: #6a5acd;
}

.container .content p {
    font-size: 18px; /* 동일한 크기를 지정 */
    margin-bottom: 10px;
    text-align: left;
    opacity: 0.5;
    line-height: 1.2;
}

.container:nth-child(even) p {
    font-size: 18px; /* 동일한 크기를 지정 */
    color: black;
    margin-bottom: 10px;
    text-align: left;
    opacity: 0.5;
    line-height: 1;
}
.container .content .star-p{
    font-size: 14px;
    margin-top: -2px;
}
ul {
    padding: 0;
    margin: 20px 0;
    list-style-type: none;
    padding-left: 0;
}

ul li {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
    text-align: left;
    white-space: nowrap;
    padding-left: 1.5rem;
    position: relative;
    line-height: 1.7;
}
  li::before {
    content: "✅";
    position: absolute;
    color: #4caf50;
    font-size: 1rem;
    margin-right: 50px;
  }

.btn{
    display: flex;
    align-items: flex-start;
    /* margin-left: -90px; */
    /* padding: 10px 20px; */
}
.youtube-btn {
    background-color: #5a4cb0;
    border: none;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.goToGooglebtn, .goToAppStorebtn {
    background-color: #5a4cb0;
    border: none;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;
    margin-left: 10px;
    cursor: pointer;
}

li::before {
    content: "✅";
    position: absolute;
    left: 0;
    color: #4caf50;
    margin-top: 0px;
  }

@media all and (min-width: 810px) and (max-width: 1099px) {
    .container .content h2{
        font-size:  28px;
    }
    .container .content p{
        font-size:  16px;
    }
    .container .content ul li {
        font-size: 14px;
        line-height: 2;
    }
    .container:nth-child(even) .content {
        padding-left: 3%;
    }
    .container img{
        max-width: 500px;
        width: 100%;
    }
    .container:nth-child(even) img{
        width: 100%;
        align-items: flex-start;
    }
    .container .content .star-p{
        font-size: 12px;
    }
}

@media all and (max-width: 809px) {
    .second-section{
        margin-bottom: -200px;
    }
    .container {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 20px;
    }

    .container:nth-child(even) {
        flex-direction: column;
        float: left;
    }

    .container img {
        display: block;
        width: 100%;
        max-width: 300px;
        margin-left: -30px;
    }

    .container .content h2,
    .container .content p{
        white-space: normal;
        text-align: left;
        margin-left: -50px;
    }

    .youtube-btn, .goToGooglebtn, .goToAppStorebtn {
        margin-left: 0;
        margin-right: 0;
    }

    .container ul li {
        display: none;
    }

    h2 {
        font-size: 20px;
        font-weight: normal;
        color: white;
        margin-bottom: 20px;
        text-align: left;
        line-height: 1.3;
    }
    .youtube-btn, .goToGooglebtn, .goToAppStorebtn{
        display: none;
    }
}
